import React, { forwardRef } from "react";
import { cn } from "../utils/classNames";

const Logo = forwardRef(({ className, ...props }, ref) => (
  <svg ref={ref} {...props} className={cn("", className)} width={168} height={35} viewBox="0 0 168 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.31551 0.608668H12.4575V1.31759H11.7207L9.31024 8.39133H8.36817L5.92614 1.31759L6.61033 2.29043H5.86824L6.5577 1.31759L4.07356 8.39133H3.14727L0.73682 1.31759H0V0.608668H3.13148V1.31759H1.54732L3.92093 8.39133L3.21569 7.44437H3.98409L3.32095 8.39133L5.82614 1.31759H4.45776V0.608668H8.02607V1.31759H6.70506L9.17341 8.39133L8.4629 7.44437H9.2313L8.57342 8.39133L10.9681 1.31759H9.31551V0.608668Z" fill="#191919"/>
    <path d="M18.0596 5.19858V5.88163H13.8071V5.19858H18.0596ZM19.328 7.68241H20.0911V8.39133H16.7175V7.68241H18.5017L15.7965 1.21928L16.2859 1.55045H15.5175L16.007 1.21928L13.3018 7.68241H15.086V8.39133H11.7492V7.68241H12.5176L15.4754 0.608668H16.3649L19.328 7.68241Z" fill="#191919"/>
    <path d="M25.1205 4.32406C25.3732 4.35166 25.5942 4.42928 25.7837 4.55692C25.9731 4.68111 26.1065 4.85533 26.1837 5.07956L27.1521 7.68241H28.0204V8.39133H26.5731L25.5521 5.52458C25.4539 5.25895 25.3363 5.07439 25.1995 4.97089C25.0662 4.86395 24.8679 4.81048 24.6048 4.81048H24.2153L25.1205 4.32406ZM23.3469 1.31759V0.608668H24.7837C25.6854 0.608668 26.3626 0.786331 26.8152 1.14166C27.2678 1.49698 27.4941 1.98512 27.4941 2.60608C27.4941 2.94071 27.424 3.24601 27.2836 3.52199C27.1468 3.79452 26.9503 4.02393 26.6942 4.21022C26.4416 4.39306 26.1381 4.51897 25.7837 4.58797V4.72768L24.7469 4.81048H22.4732V4.12225H24.7416C25.1626 4.12225 25.5188 4.06361 25.81 3.94631C26.1012 3.82902 26.3205 3.66171 26.4679 3.44437C26.6152 3.22704 26.6889 2.96658 26.6889 2.663C26.6889 2.21798 26.517 1.88335 26.1731 1.65912C25.8328 1.43144 25.3591 1.31759 24.7521 1.31759H23.3469ZM20.5259 1.31759V0.608668H23.8574V1.31759H22.5838V7.68241H23.8574V8.39133H20.5259V7.68241H21.7996V1.31759H20.5259Z" fill="#191919"/>
    <path d="M32.3111 8.49483C31.5638 8.49483 30.969 8.34476 30.527 8.04463C30.0849 7.74105 29.8129 7.3288 29.7112 6.80789H29.527L29.6743 6.03169C29.7726 6.59401 30.0445 7.03213 30.4901 7.34605C30.9357 7.65998 31.5041 7.81695 32.1953 7.81695C32.8128 7.81695 33.2848 7.69276 33.6111 7.44437C33.9374 7.19254 34.1005 6.83204 34.1005 6.36287C34.1005 6.05239 34.0268 5.79366 33.8795 5.58668C33.7321 5.37624 33.4935 5.20548 33.1637 5.07439C32.8374 4.93985 32.4006 4.8329 31.8532 4.75356C31.1761 4.65696 30.6199 4.51207 30.1849 4.31889C29.7533 4.1257 29.4322 3.87904 29.2217 3.57891C29.0112 3.27878 28.9059 2.92173 28.9059 2.50776C28.9059 1.87646 29.1252 1.38486 29.5638 1.03299C30.0059 0.681113 30.6129 0.505175 31.3848 0.505175C31.869 0.505175 32.2795 0.57417 32.6164 0.71216C32.9567 0.846701 33.2251 1.04161 33.4216 1.2969C33.6181 1.55218 33.7462 1.85576 33.8058 2.20763H33.9847L33.8321 2.96831C33.7409 2.40944 33.4865 1.97305 33.069 1.65912C32.655 1.34174 32.1287 1.18305 31.4901 1.18305C30.9392 1.18305 30.5041 1.2969 30.1849 1.52458C29.8656 1.74881 29.7059 2.06447 29.7059 2.47154C29.7059 2.73717 29.7814 2.96658 29.9322 3.15977C30.0831 3.3495 30.3252 3.51164 30.6585 3.64618C30.9918 3.77727 31.4304 3.89112 31.9743 3.98771C32.676 4.09465 33.2409 4.24472 33.669 4.4379C34.097 4.63109 34.4093 4.8812 34.6058 5.18823C34.8058 5.49181 34.9058 5.86611 34.9058 6.31113C34.9058 7.00108 34.6795 7.53752 34.2268 7.92044C33.7742 8.30336 33.1356 8.49483 32.3111 8.49483ZM28.8849 8.39133V6.03169H29.6743V8.39133H28.8849ZM33.8321 2.96831V0.608668H34.6216V2.96831H33.8321Z" fill="#191919"/>
    <path d="M35.664 7.68241L40.8113 1.34864V1.12096L41.8165 1.31759L36.6693 7.65136V7.89457L35.664 7.68241ZM41.8165 0.608668V1.31759H36.6009V2.84411H35.8167V0.608668H41.8165ZM41.0955 6.15589H41.8849V8.39133H35.664V7.68241H41.0955V6.15589Z" fill="#191919"/>
    <path d="M49.9607 0.608668V3.09767H49.1713V1.31759H46.6135V7.68241H47.8819V8.39133H44.5504V7.68241H45.824V1.31759H43.2715V3.09767H42.482V0.608668H49.9607Z" fill="#191919"/>
    <path d="M55.6509 5.19858V5.88163H51.3984V5.19858H55.6509ZM56.9193 7.68241H57.6825V8.39133H54.3089V7.68241H56.093L53.3879 1.21928L53.8773 1.55045H53.1089L53.5984 1.21928L50.8932 7.68241H52.6773V8.39133H49.3406V7.68241H50.109L53.0668 0.608668H53.9563L56.9193 7.68241Z" fill="#191919"/>
    <path d="M64.5368 0.608668V3.09767H63.7473V1.31759H61.1895V7.68241H62.4579V8.39133H59.1264V7.68241H60.4001V1.31759H57.8475V3.09767H57.0581V0.608668H64.5368Z" fill="#191919"/>
    <path d="M71.8761 8.49483C71.1288 8.49483 70.534 8.34476 70.0919 8.04463C69.6499 7.74105 69.3779 7.3288 69.2762 6.80789H69.092L69.2393 6.03169C69.3376 6.59401 69.6095 7.03213 70.0551 7.34605C70.5007 7.65998 71.0691 7.81695 71.7603 7.81695C72.3778 7.81695 72.8498 7.69276 73.1761 7.44437C73.5024 7.19254 73.6655 6.83204 73.6655 6.36287C73.6655 6.05239 73.5918 5.79366 73.4445 5.58668C73.2971 5.37624 73.0585 5.20548 72.7287 5.07439C72.4024 4.93985 71.9656 4.8329 71.4182 4.75356C70.7411 4.65696 70.1849 4.51207 69.7499 4.31889C69.3183 4.1257 68.9972 3.87904 68.7867 3.57891C68.5762 3.27878 68.4709 2.92173 68.4709 2.50776C68.4709 1.87646 68.6902 1.38486 69.1288 1.03299C69.5709 0.681113 70.1779 0.505175 70.9498 0.505175C71.434 0.505175 71.8445 0.57417 72.1814 0.71216C72.5217 0.846701 72.7901 1.04161 72.9866 1.2969C73.1831 1.55218 73.3111 1.85576 73.3708 2.20763H73.5497L73.3971 2.96831C73.3059 2.40944 73.0515 1.97305 72.634 1.65912C72.22 1.34174 71.6937 1.18305 71.0551 1.18305C70.5042 1.18305 70.0691 1.2969 69.7499 1.52458C69.4306 1.74881 69.2709 2.06447 69.2709 2.47154C69.2709 2.73717 69.3464 2.96658 69.4972 3.15977C69.6481 3.3495 69.8902 3.51164 70.2235 3.64618C70.5569 3.77727 70.9954 3.89112 71.5393 3.98771C72.241 4.09465 72.8059 4.24472 73.234 4.4379C73.662 4.63109 73.9743 4.8812 74.1708 5.18823C74.3708 5.49181 74.4708 5.86611 74.4708 6.31113C74.4708 7.00108 74.2445 7.53752 73.7918 7.92044C73.3392 8.30336 72.7006 8.49483 71.8761 8.49483ZM68.4499 8.39133V6.03169H69.2393V8.39133H68.4499ZM73.3971 2.96831V0.608668H74.1866V2.96831H73.3971Z" fill="#191919"/>
    <path d="M81.1333 5.19858V5.88163H76.8808V5.19858H81.1333ZM82.4017 7.68241H83.1648V8.39133H79.7912V7.68241H81.5754L78.8702 1.21928L79.3597 1.55045H78.5913L79.0807 1.21928L76.3756 7.68241H78.1597V8.39133H74.823V7.68241H75.5914L78.5492 0.608668H79.4386L82.4017 7.68241Z" fill="#191919"/>
    <path d="M88.4785 8.39133L85.6312 1.26067L86.2049 0.608668L88.9995 7.64618H88.789L91.5889 0.608668L92.1047 1.26067L89.2521 8.39133H88.4785ZM83.5997 8.39133V7.68241H84.8733V1.31759H83.5997V0.608668H86.1996L86.1101 1.31759H85.6154V7.68241H86.9364V8.39133H83.5997ZM90.7731 8.39133V7.68241H92.1205V1.31759H91.5679L91.5836 0.608668H94.1783V1.31759H92.9047V7.68241H94.1783V8.39133H90.7731Z" fill="#191919"/>
    <path d="M98.6873 8.5C98.0873 8.5 97.5382 8.40341 97.04 8.21022C96.5417 8.01358 96.1119 7.7376 95.7505 7.38228C95.3892 7.0235 95.1102 6.59918 94.9137 6.10931C94.7172 5.616 94.619 5.07266 94.619 4.4793C94.619 3.89284 94.7172 3.3564 94.9137 2.86999C95.1102 2.38357 95.3892 1.96442 95.7505 1.61255C96.1119 1.25722 96.54 0.982967 97.0347 0.78978C97.533 0.596593 98.0838 0.5 98.6873 0.5C99.2908 0.5 99.8399 0.596593 100.335 0.78978C100.833 0.982967 101.263 1.25722 101.624 1.61255C101.985 1.96442 102.264 2.38357 102.461 2.86999C102.661 3.3564 102.761 3.89284 102.761 4.4793C102.761 5.07266 102.663 5.616 102.466 6.10931C102.27 6.59918 101.989 7.0235 101.624 7.38228C101.263 7.7376 100.833 8.01358 100.335 8.21022C99.8399 8.40341 99.2908 8.5 98.6873 8.5ZM98.6873 7.7445C99.3294 7.7445 99.8925 7.60651 100.377 7.33053C100.864 7.0511 101.243 6.66645 101.514 6.17658C101.787 5.68672 101.924 5.12096 101.924 4.4793C101.924 3.84454 101.787 3.28568 101.514 2.80272C101.243 2.3163 100.864 1.93683 100.377 1.66429C99.8925 1.39176 99.3294 1.2555 98.6873 1.2555C98.0487 1.2555 97.4856 1.39176 96.9979 1.66429C96.5137 1.93683 96.1348 2.3163 95.8611 2.80272C95.5874 3.28568 95.4506 3.84454 95.4506 4.4793C95.4506 5.12096 95.5874 5.68672 95.8611 6.17658C96.1348 6.66645 96.5137 7.0511 96.9979 7.33053C97.4821 7.60651 98.0452 7.7445 98.6873 7.7445Z" fill="#191919"/>
    <path d="M107.736 8.5C107.157 8.5 106.625 8.40168 106.141 8.20504C105.657 8.00841 105.238 7.7307 104.883 7.37193C104.529 7.01315 104.254 6.58883 104.057 6.09897C103.864 5.60565 103.768 5.06576 103.768 4.4793C103.768 3.89629 103.862 3.36158 104.052 2.87516C104.241 2.38875 104.511 1.9696 104.862 1.61772C105.217 1.26585 105.636 0.993316 106.12 0.800129C106.604 0.603493 107.138 0.505175 107.72 0.505175C108.292 0.505175 108.787 0.598319 109.204 0.784606C109.622 0.970893 109.959 1.23652 110.215 1.5815C110.474 1.92648 110.652 2.33872 110.746 2.81824H110.999L110.783 3.66171C110.622 2.90966 110.271 2.3232 109.731 1.90233C109.19 1.47801 108.527 1.26585 107.741 1.26585C107.117 1.26585 106.569 1.40211 106.099 1.67464C105.629 1.94373 105.262 2.31975 104.999 2.80272C104.736 3.28568 104.604 3.84454 104.604 4.4793C104.604 4.95882 104.682 5.39866 104.836 5.79884C104.99 6.19901 105.206 6.54398 105.483 6.83376C105.76 7.12354 106.09 7.34778 106.473 7.50647C106.855 7.66171 107.276 7.73933 107.736 7.73933C108.255 7.73933 108.725 7.63928 109.146 7.4392C109.567 7.23911 109.917 6.95623 110.194 6.59056C110.474 6.22143 110.666 5.79194 110.767 5.30207L111.578 5.46766C111.452 6.07827 111.211 6.61126 110.857 7.06662C110.502 7.52199 110.059 7.87559 109.525 8.12743C108.995 8.37581 108.399 8.5 107.736 8.5ZM110.783 3.66171V0.608668H111.567V3.66171H110.783Z" fill="#191919"/>
    <path d="M119.383 4.12225V4.81048H114.015V4.12225H119.383ZM112.299 1.31759V0.608668H115.631V1.31759H114.357V7.68241H115.631V8.39133H112.299V7.68241H113.573V1.31759H112.299ZM117.847 1.31759V0.608668H121.173V1.31759H119.905V7.68241H121.173V8.39133H117.847V7.68241H119.12V1.31759H117.847Z" fill="#191919"/>
    <path d="M125.691 8.5C125.091 8.5 124.542 8.40341 124.044 8.21022C123.545 8.01358 123.116 7.7376 122.754 7.38228C122.393 7.0235 122.114 6.59918 121.917 6.10931C121.721 5.616 121.623 5.07266 121.623 4.4793C121.623 3.89284 121.721 3.3564 121.917 2.86999C122.114 2.38357 122.393 1.96442 122.754 1.61255C123.116 1.25722 123.544 0.982967 124.038 0.78978C124.537 0.596593 125.088 0.5 125.691 0.5C126.294 0.5 126.844 0.596593 127.338 0.78978C127.837 0.982967 128.266 1.25722 128.628 1.61255C128.989 1.96442 129.268 2.38357 129.465 2.86999C129.665 3.3564 129.765 3.89284 129.765 4.4793C129.765 5.07266 129.666 5.616 129.47 6.10931C129.273 6.59918 128.993 7.0235 128.628 7.38228C128.266 7.7376 127.837 8.01358 127.338 8.21022C126.844 8.40341 126.294 8.5 125.691 8.5ZM125.691 7.7445C126.333 7.7445 126.896 7.60651 127.38 7.33053C127.868 7.0511 128.247 6.66645 128.517 6.17658C128.791 5.68672 128.928 5.12096 128.928 4.4793C128.928 3.84454 128.791 3.28568 128.517 2.80272C128.247 2.3163 127.868 1.93683 127.38 1.66429C126.896 1.39176 126.333 1.2555 125.691 1.2555C125.052 1.2555 124.489 1.39176 124.002 1.66429C123.517 1.93683 123.138 2.3163 122.865 2.80272C122.591 3.28568 122.454 3.84454 122.454 4.4793C122.454 5.12096 122.591 5.68672 122.865 6.17658C123.138 6.66645 123.517 7.0511 124.002 7.33053C124.486 7.60651 125.049 7.7445 125.691 7.7445Z" fill="#191919"/>
    <path d="M133.343 8.39133V7.68241H134.059C134.718 7.68241 135.294 7.54787 135.785 7.27878C136.279 7.00625 136.664 6.6285 136.937 6.14554C137.215 5.66257 137.353 5.10716 137.353 4.4793C137.353 3.85489 137.215 3.30466 136.937 2.82859C136.664 2.35252 136.279 1.98167 135.785 1.71604C135.294 1.45041 134.718 1.31759 134.059 1.31759H133.343V0.608668H134.059C134.883 0.608668 135.604 0.774256 136.222 1.10543C136.843 1.43316 137.325 1.88853 137.669 2.47154C138.013 3.0511 138.185 3.72035 138.185 4.4793C138.185 5.2486 138.013 5.9282 137.669 6.51811C137.325 7.10457 136.843 7.56339 136.222 7.89457C135.604 8.22574 134.883 8.39133 134.059 8.39133H133.343ZM130.206 8.39133V7.68241H131.48V1.31759H130.206V0.608668H133.616V1.31759H132.264V7.68241H133.616V8.39133H130.206Z" fill="#191919"/>
    <path d="M143.258 8.5C142.658 8.5 142.109 8.40341 141.611 8.21022C141.113 8.01358 140.683 7.7376 140.322 7.38228C139.96 7.0235 139.681 6.59918 139.485 6.10931C139.288 5.616 139.19 5.07266 139.19 4.4793C139.19 3.89284 139.288 3.3564 139.485 2.86999C139.681 2.38357 139.96 1.96442 140.322 1.61255C140.683 1.25722 141.111 0.982967 141.606 0.78978C142.104 0.596593 142.655 0.5 143.258 0.5C143.862 0.5 144.411 0.596593 144.906 0.78978C145.404 0.982967 145.834 1.25722 146.195 1.61255C146.556 1.96442 146.835 2.38357 147.032 2.86999C147.232 3.3564 147.332 3.89284 147.332 4.4793C147.332 5.07266 147.234 5.616 147.037 6.10931C146.841 6.59918 146.56 7.0235 146.195 7.38228C145.834 7.7376 145.404 8.01358 144.906 8.21022C144.411 8.40341 143.862 8.5 143.258 8.5ZM143.258 7.7445C143.9 7.7445 144.464 7.60651 144.948 7.33053C145.435 7.0511 145.814 6.66645 146.085 6.17658C146.358 5.68672 146.495 5.12096 146.495 4.4793C146.495 3.84454 146.358 3.28568 146.085 2.80272C145.814 2.3163 145.435 1.93683 144.948 1.66429C144.464 1.39176 143.9 1.2555 143.258 1.2555C142.62 1.2555 142.057 1.39176 141.569 1.66429C141.085 1.93683 140.706 2.3163 140.432 2.80272C140.158 3.28568 140.022 3.84454 140.022 4.4793C140.022 5.12096 140.158 5.68672 140.432 6.17658C140.706 6.66645 141.085 7.0511 141.569 7.33053C142.053 7.60651 142.616 7.7445 143.258 7.7445Z" fill="#191919"/>
    <path d="M156.649 0.608668H159.791V1.31759H159.054L156.643 8.39133H155.701L153.259 1.31759L153.943 2.29043H153.201L153.891 1.31759L151.407 8.39133H150.48L148.07 1.31759H147.333V0.608668H150.465V1.31759H148.88L151.254 8.39133L150.549 7.44437H151.317L150.654 8.39133L153.159 1.31759H151.791V0.608668H155.359V1.31759H154.038L156.507 8.39133L155.796 7.44437H156.564L155.907 8.39133L158.301 1.31759H156.649V0.608668Z" fill="#191919"/>
    <path d="M167.268 1.31759L164.39 4.97089H163.705L160.821 1.31759H160.09V0.608668H163.232V1.31759H161.774L164.29 4.53105L163.658 4.24127H164.426L163.895 4.53105L166.405 1.31759H164.847V0.608668H168V1.31759H167.268ZM164.437 4.39651V7.68241H165.705V8.39133H162.374V7.68241H163.648V4.39651H164.437Z" fill="#191919"/>
    <path d="M16.823 23.1026V25.7436H4.39928V23.1026H16.823ZM0 17.6538V14.8205H8.50183V17.6538H5.88291V31.3462H8.50183V34.1795H0V31.3462H2.63183V17.6538H0ZM12.9656 17.6538V14.8205H21.4674V17.6538H18.8485V31.3462H21.4674V34.1795H12.9656V31.3462H15.5974V17.6538H12.9656Z" fill="#1E1E1E"/>
    <path d="M32.588 34.5C31.0829 34.5 29.7067 34.2564 28.4596 33.7692C27.2125 33.2821 26.1374 32.5897 25.2344 31.6923C24.3313 30.7949 23.6303 29.735 23.1315 28.5128C22.6412 27.2821 22.3961 25.9274 22.3961 24.4487C22.3961 22.9872 22.6412 21.6496 23.1315 20.4359C23.6303 19.2222 24.3313 18.1752 25.2344 17.2949C26.1374 16.406 27.2125 15.7179 28.4596 15.2308C29.7067 14.7436 31.0829 14.5 32.588 14.5C34.1017 14.5 35.4778 14.7436 36.7164 15.2308C37.9635 15.7179 39.0386 16.406 39.9416 17.2949C40.8533 18.1752 41.5543 19.2222 42.0445 20.4359C42.5434 21.6496 42.7928 22.9872 42.7928 24.4487C42.7928 25.9274 42.5434 27.2821 42.0445 28.5128C41.5543 29.735 40.8533 30.7949 39.9416 31.6923C39.0386 32.5897 37.9635 33.2821 36.7164 33.7692C35.4778 34.2564 34.1017 34.5 32.588 34.5ZM32.588 31.359C33.9297 31.359 35.108 31.0684 36.1229 30.4872C37.1378 29.8974 37.9334 29.0855 38.5096 28.0513C39.0859 27.0085 39.374 25.8077 39.374 24.4487C39.374 23.0983 39.0859 21.9103 38.5096 20.8846C37.9334 19.859 37.1378 19.0641 36.1229 18.5C35.108 17.9274 33.934 17.641 32.6009 17.641C31.2592 17.641 30.0766 17.9274 29.0531 18.5C28.0382 19.0641 27.2426 19.859 26.6664 20.8846C26.0987 21.9103 25.8149 23.0983 25.8149 24.4487C25.8149 25.8077 26.0987 27.0085 26.6664 28.0513C27.2426 29.0855 28.0382 29.8974 29.0531 30.4872C30.0766 31.0684 31.2549 31.359 32.588 31.359Z" fill="#1E1E1E"/>
    <path d="M58.9409 34.1795L47.2009 14.8205H50.8648L62.6048 34.1795H58.9409ZM43.7176 17.6538V14.8205H50.839L50.5036 18.5385H49.2264V31.3462H51.8453V34.1795H43.7176V31.3462H46.3365V17.6538H43.7176ZM57.5605 17.6538V14.8205H65.6882V17.6538H63.0693V34.1795H59.586L58.4765 29.8205H60.1794V17.6538H57.5605Z" fill="#1E1E1E"/>
    <path d="M74.7597 34.1795V31.3462H76.3078C77.6581 31.3462 78.8408 31.0556 79.8556 30.4744C80.8791 29.8846 81.6747 29.0727 82.2423 28.0385C82.8186 26.9957 83.1067 25.7949 83.1067 24.4359C83.1067 23.1026 82.8186 21.9273 82.2423 20.9103C81.6747 19.8846 80.8791 19.0855 79.8556 18.5128C78.8408 17.9402 77.6581 17.6538 76.3078 17.6538H74.7597V14.8205H76.3336C78.3462 14.8205 80.118 15.2308 81.6489 16.0513C83.1884 16.8718 84.3839 18.0043 85.2354 19.4487C86.0955 20.8932 86.5255 22.5598 86.5255 24.4487C86.5255 26.3632 86.0955 28.0513 85.2354 29.5128C84.3839 30.9744 83.1884 32.1197 81.6489 32.9487C80.118 33.7692 78.3462 34.1795 76.3336 34.1795H74.7597ZM66.7481 34.1795V31.3462H69.3799V17.6538H66.7481V14.8205H75.6112V17.6538H72.631V31.3462H75.6112V34.1795H66.7481Z" fill="#1E1E1E"/>
    <path d="M102.137 25.5V28.1282H91.5973V25.5H102.137ZM105.401 31.3462H107.04V34.1795H98.4735V31.3462H101.931L96.2933 16.8077L98.0478 18.3462H95.5192L97.2092 16.8077L91.5715 31.3462H95.0806V34.1795H86.7206V31.3462H88.372L94.9644 14.8205H98.8219L105.401 31.3462Z" fill="#1E1E1E"/>
    <path d="M116.007 17.6538V14.8205H118.497C120.44 14.8205 121.967 15.2436 123.077 16.0897C124.195 16.9274 124.754 18.1667 124.754 19.8077C124.754 21.4402 124.186 22.6368 123.051 23.3974C121.915 24.1496 120.26 24.5256 118.084 24.5256H112.949V23.0897H118.11C118.892 23.0897 119.52 22.9573 119.993 22.6923C120.466 22.4274 120.81 22.0812 121.025 21.6538C121.24 21.2179 121.348 20.7521 121.348 20.2564C121.348 19.3846 121.038 18.735 120.419 18.3077C119.8 17.8718 118.978 17.6538 117.955 17.6538H116.007ZM115.749 31.3462H118.29C119.426 31.3462 120.337 31.1068 121.025 30.6282C121.722 30.141 122.07 29.4359 122.07 28.5128C122.07 28.1197 121.997 27.7564 121.851 27.4231C121.705 27.0812 121.477 26.7863 121.167 26.5385C120.858 26.2821 120.453 26.0855 119.954 25.9487C119.464 25.812 118.867 25.7436 118.161 25.7436H112.949V23.9359H118.161C119.34 23.9359 120.38 24.0256 121.283 24.2051C122.195 24.3761 122.96 24.6581 123.58 25.0513C124.208 25.4359 124.681 25.9402 124.999 26.5641C125.317 27.188 125.476 27.953 125.476 28.859C125.476 30.5427 124.93 31.8504 123.838 32.7821C122.754 33.7137 121.258 34.1795 119.348 34.1795H115.749V31.3462ZM108.047 17.6538V14.8205H116.6V17.6538H113.93V31.3462H116.6V34.1795H108.047V31.3462H110.679V17.6538H108.047ZM120.213 23.8077H121.451V24.5641H120.213V23.8077Z" fill="#EC1A26"/>
    <path d="M137.863 34.5C136.358 34.5 134.982 34.2564 133.735 33.7692C132.488 33.2821 131.412 32.5897 130.509 31.6923C129.606 30.7949 128.905 29.735 128.407 28.5128C127.916 27.2821 127.671 25.9274 127.671 24.4487C127.671 22.9872 127.916 21.6496 128.407 20.4359C128.905 19.2222 129.606 18.1752 130.509 17.2949C131.412 16.406 132.488 15.7179 133.735 15.2308C134.982 14.7436 136.358 14.5 137.863 14.5C139.377 14.5 140.753 14.7436 141.991 15.2308C143.239 15.7179 144.314 16.406 145.217 17.2949C146.128 18.1752 146.829 19.2222 147.32 20.4359C147.818 21.6496 148.068 22.9872 148.068 24.4487C148.068 25.9274 147.818 27.2821 147.32 28.5128C146.829 29.735 146.128 30.7949 145.217 31.6923C144.314 32.5897 143.239 33.2821 141.991 33.7692C140.753 34.2564 139.377 34.5 137.863 34.5ZM137.863 31.359C139.205 31.359 140.383 31.0684 141.398 30.4872C142.413 29.8974 143.208 29.0855 143.785 28.0513C144.361 27.0085 144.649 25.8077 144.649 24.4487C144.649 23.0983 144.361 21.9103 143.785 20.8846C143.208 19.859 142.413 19.0641 141.398 18.5C140.383 17.9274 139.209 17.641 137.876 17.641C136.534 17.641 135.352 17.9274 134.328 18.5C133.313 19.0641 132.518 19.859 131.941 20.8846C131.374 21.9103 131.09 23.0983 131.09 24.4487C131.09 25.8077 131.374 27.0085 131.941 28.0513C132.518 29.0855 133.313 29.8974 134.328 30.4872C135.352 31.0684 136.53 31.359 137.863 31.359Z" fill="#EC1A26"/>
    <path d="M166.104 17.6538L159.15 25.5513L160.234 23.3077V25.7692L159.421 23.5L166.039 31.3462H168V34.1795H159.214V31.3462H161.911L157.047 25.3846L159.163 26.359H156.686L158.544 25.3846L153.706 31.3462H156.737V34.1795H148.107V31.3462H150.016L156.957 23.4231L155.873 25.6667V23.2051L156.699 25.4615L150.068 17.6538H148.107V14.8205H156.905V17.6538H154.196L159.06 23.5897L156.944 22.6154H159.421L157.576 23.5897L162.414 17.6538H159.369V14.8205H168V17.6538H166.104Z" fill="#EC1A26"/>
  </svg>
));

Logo.displayName = "Logo";
export default Logo;
