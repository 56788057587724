"use client";

import { useEffect, useState } from "react";
import Logo from "@/icons/Logo";
import Phone from "@/icons/Phone";
import { Button, Link } from "@nextui-org/react";
import { usePathname } from "next/navigation";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const currentPath = usePathname();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    }

    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  return (
    <>
      <nav className={`fixed z-50 flex w-full select-none justify-center border-b border-b-neutral-200 bg-neutral-50 ${isScrolled ? "shadow-sm shadow-neutral-200" : ""}`}>
        <div className="flex h-16 w-full max-w-7xl items-center justify-between px-6">
          <Link href="/" aria-label="Strona Główna">
            <Logo className="h-6 w-auto sm:h-7" />
          </Link>

          <div className="hidden w-full items-center justify-end gap-9 md:flex">
            {currentPath.includes("/blog") || currentPath.includes("/privacy-policy") ? (
              <div className="flex gap-6">
                <Link href="/" className={`text-sm font-semibold text-neutral-500 ${currentPath.includes("/blog") ? "" : "text-red-600"}`}>
                  Strona Główna
                </Link>
                <Link href="/blog" className={`text-sm font-semibold text-neutral-500 ${currentPath === "/blog" ? "text-red-600" : ""}`}>
                  Blog
                </Link>
              </div>
            ) : (
              <div className="flex gap-6">
                <Link href="#o-nas" className="text-sm font-semibold text-neutral-500">
                  O nas
                </Link>
                <Link href="#uslugi" className="text-sm font-semibold text-neutral-500">
                  Usługi
                </Link>
                <Link href="#blog" className="text-sm font-semibold text-neutral-500">
                  Blog
                </Link>
                <Link href="#opinie" className="text-sm font-semibold text-neutral-500">
                  Opinie
                </Link>
                <Link href="#kontakt" className="text-sm font-semibold text-neutral-500">
                  Kontakt
                </Link>
              </div>
            )}
            <Button className="flex gap-2 bg-neutral-950 text-xs font-semibold text-neutral-50" as={Link} href="tel:+48502928808">
              <Phone className="h-5 w-5" /> Zadzwoń teraz
            </Button>
          </div>
          <button onClick={toggleMenu} className="flex h-9 w-9 flex-col items-center justify-center md:hidden" aria-label="Rozwiń menu">
            <span
              className={`block h-[2.2px] w-6 rounded-sm bg-neutral-950 transition-all duration-300 ease-out ${isMenuOpen ? "translate-y-[6.2px] rotate-45" : "-translate-y-0.5"}`}
            ></span>
            <span className={`my-1 block h-[2.4px] w-6 rounded-sm bg-neutral-950 transition-all duration-300 ease-out ${isMenuOpen ? "opacity-0" : "opacity-100"}`}></span>
            <span
              className={`block h-[2.2px] w-6 rounded-sm bg-neutral-950 transition-all duration-300 ease-out ${isMenuOpen ? "-translate-y-[6.2px] -rotate-45" : "translate-y-0.5"}`}
            ></span>
          </button>
        </div>
      </nav>
      <div className={`fixed bottom-0 z-40 h-full w-full bg-neutral-100 p-6 transition-transform duration-500 ease-in-out ${isMenuOpen ? "-translate-y-0" : "-translate-y-full"}`}>
        <div
          className={`flex h-full flex-col items-center justify-center gap-6 overflow-y-auto transition-opacity duration-300 ease-in-out ${isMenuOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"}`}
        >
          <Link
            href="/"
            className={`font-semibold text-neutral-500 transition-opacity duration-500 ease-in-out ${isMenuOpen ? "opacity-100 delay-100" : "opacity-0"} ${currentPath.includes("/blog") ? "" : "text-red-600"}`}
          >
            Strona Główna
          </Link>
          <Link
            href="/blog"
            className={`font-semibold text-neutral-500 transition-opacity duration-500 ease-in-out ${isMenuOpen ? "opacity-100 delay-300" : "opacity-0"} ${currentPath === "/blog" ? "text-red-600" : ""}`}
          >
            Blog
          </Link>
        </div>
      </div>
    </>
  );
}
